<template>
  <div>
    <b-row>
      <b-card class="w-100 mx-1">
        <b-tabs>
          <b-tab
            title="Configuración del Perfil"
            active
            v-if="user.type === 'pre-pago' && !isAdminOrStaff"
          >
            <b-card
              class="px-2 py-1"
              v-if="user.type === 'pre-pago' && !isAdminOrStaff"
            >
              <b-card-body class="card-body px-0">
                <div v-if="user.type === 'pre-pago' && !isAdminOrStaff">
                  <p>
                    Por favor, para comenzar a operar es necesario que completes
                    todos los datos de tu Perfil
                  </p>
                  <b-progress show-progress class="perfil-progres">
                    <b-progress-bar
                      :label="`${advance}%`"
                      :value="advance"
                      variant="primary"
                    ></b-progress-bar>
                  </b-progress>
                  <br />
                  <div>
                    <p>
                      <feather-icon
                        size="20"
                        v-if="advance >= 90"
                        icon="CheckIcon"
                        style="color: green"
                      />
                      <feather-icon
                        size="20"
                        v-else
                        icon="AlertTriangleIcon"
                        style="color: red"
                      />
                      <span class="ml-1"
                        >Formulario de Registro Completado.</span
                      >
                    </p>
                    <p>
                      <feather-icon
                        size="20"
                        v-if="!!user.point"
                        icon="CheckIcon"
                        style="color: green"
                      />
                      <feather-icon
                        size="20"
                        v-else
                        icon="AlertTriangleIcon"
                        style="color: red"
                      />
                      <span class="ml-1"
                        >Punto de de Entrega Seleccionado.</span
                      >
                    </p>
                    <p>
                      <feather-icon
                        size="20"
                        v-if="!!user.keopsUser"
                        icon="CheckIcon"
                        style="color: green"
                      />
                      <feather-icon
                        size="20"
                        v-else
                        icon="AlertTriangleIcon"
                        style="color: red"
                      />
                      <span class="ml-1">
                        Información de Facturación Validada.</span
                      >
                    </p>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab
            title="Datos"
            v-if="user.retirement === 'client & point' && !isAdminOrStaff"
          >
            <BusinessData
              id="s1"
              v-if="user.retirement === 'client & point' && !isAdminOrStaff"
              @chageUser="onChangeUser"
            />
          </b-tab>
          <b-tab
            title="API"
            v-if="
              isCustomer &&
              (user.type === 'post-pago' ||
                (user.advance === 100 && user.keopsUser))
            "
          >
            <CredentialData
              id="s4"
              v-if="
                isCustomer &&
                (user.type === 'post-pago' ||
                  (user.advance === 100 && user.keopsUser))
              "
            />
          </b-tab>
          <b-tab title="Cambiar Contraseña">
            <BasicData />
          </b-tab>

          <b-tab title="Label">
            <LabelProductData />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-row>
  </div>
</template>
<script>
import UserService from "../../../services/UserService";

import BasicData from "./forms/BasicData";
import LabelProductData from "./forms/LabelProductData.vue";
import BusinessData from "./forms/BusinessData";
import CredentialData from "./forms/CredentialData";

export default {
  name: "Profile",
  components: {
    BasicData,
    BusinessData,
    CredentialData,
    LabelProductData,
  },
  data() {
    return {
      advance: JSON.parse(localStorage.getItem("user")).advance,
      isLoading: false,
      fullPage: true,
      isAdminOrStaff: UserService.isAdminOrStaff(),
      isCustomer: UserService.isCustomer(),
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    onChangeUser(user) {
      this.user.point = user.point;
      const advance =
        [
          user.brand,
          user.dni,
          user.name,
          user.last_name,
          user.phone,
          user.type_document,
          user.direction.province_id,
          user.direction.location_id,
          user.direction.street,
          user.direction.height,
          user.direction.postal_code,
          user.vat_condition,
          user.voucher_type,
          user.collection,
          user.point,
          user.retirement,
          user.keopsUser,
        ].filter((item) => {
          return item && String(item).trim() !== "";
        }).length * 5.88;
      this.advance = Math.round(advance);
    },
  },
};
</script>

<style lang="scss">
.perfil-progres {
  height: 1.5rem;
  .progress-bar {
    background-color: #365f77;
    font-size: 18px !important;
    font-weight: bold !important;
  }
}

.index {
  color: #365f77;
}

.green-icon {
  color: #265a0f !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.error-icon {
  color: #a2a039 !important;
  font-size: 20px !important;
  font-weight: bold !important;
}
</style>
