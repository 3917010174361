<template>
  <b-card class="py-2 px-3">
    <b-card-title class="card-title m-0">
      <h3 class="title">Datos Básicos</h3>
      <small class="d-flex d-block">Ingresá los datos de tu Empresa</small>
      <hr />
    </b-card-title>
    <b-card-body class="card-body">
      <validation-observer ref="businessDataForm">
        <b-form @submit="onSubmit" v-if="user">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          ></loading>

          <!-- Empresa -->
          <b-form-group label="Empresa">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|max:50"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.brand"
                name="brand"
                :state="errors.length > 0 ? false : null"
                placeholder="Empresa"
                autocomplete="brand"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="TIPO">
            <b-form-group>
              <b-form-radio-group
                name="type_document"
                v-model="user.type_document"
                @input="onTypeDocument"
                :options="documents"
                :disabled="!!user.keopsUser"
              ></b-form-radio-group>
            </b-form-group>
          </b-form-group>

          <!-- CUIT/CUIL/DNI -->
          <b-form-group label="CUIT/CUIL/DNI">
            <validation-provider
              #default="{ errors }"
              name="CUIT/CUIL/DNI"
              :rules="{
                required: true,
                integer: true,
                min: user.type_document === 'dni' ? 8 : 11,
                max: user.type_document === 'dni' ? 8 : 11,
              }"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.dni"
                name="dni"
                :state="errors.length > 0 ? false : null"
                placeholder="000000000"
                autocomplete="dni"
                :disabled="!!user.keopsUser"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Nombre -->
          <b-form-group label="Nombre">
            <validation-provider
              #default="{ errors }"
              name="Nombre"
              rules="required|max:50"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.name"
                name="dni"
                :state="errors.length > 0 ? false : null"
                placeholder="Nombre"
                autocomplete="name"
                :disabled="!!user.keopsUser"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Apellido -->
          <b-form-group label="Apellido">
            <validation-provider
              #default="{ errors }"
              name="Apellido"
              rules="max:255"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.last_name"
                name="last_name"
                placeholder="Apellido"
                autocomplete="last_name"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Telefono -->
          <b-form-group label="Telefono">
            <validation-provider
              #default="{ errors }"
              name="Telefono"
              rules="max:20"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.phone"
                name="phone"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Provincia -->
          <b-form-group label="Provincia">
            <validation-provider
              #default="{ errors }"
              name="Provincia"
              rules="required"
            >
              <b-form-select
                size="sm"
                v-model="user.direction.province_id"
                name="province"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                :options="provinces"
                @input="onProvince"
              >
                <template slot="first">
                  <option :value="null" disabled>
                    Selecciona una Provincia
                  </option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Localidad -->
          <b-form-group label="Localidad">
            <validation-provider
              #default="{ errors }"
              name="Localidad"
              rules="required"
            >
              <b-form-select
                size="sm"
                v-model="user.direction.location_id"
                name="location_id"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                :options="locations"
              >
                <template slot="first">
                  <option :value="null" disabled>
                    Selecciona una localidad
                  </option>
                </template>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Calle -->
          <b-form-group label="Calle">
            <validation-provider
              #default="{ errors }"
              name="Calle"
              rules="required|max:50"
            >
              <b-form-input
                size="sm"
                v-model="user.direction.street"
                name="street"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                autocomplete="street"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Altura -->
          <b-form-group label="Altura">
            <validation-provider
              #default="{ errors }"
              name="Altura"
              rules="required|max:20"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.direction.height"
                name="height"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                autocomplete="Altura"
                placeholder="Altura"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Código postal -->
          <b-form-group label="Código postal">
            <validation-provider
              #default="{ errors }"
              name="Código postal"
              rules="required|max:10"
            >
              <b-form-input
                size="sm"
                type="text"
                v-model="user.direction.postal_code"
                name="postal_code"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                autocomplete="postal_code"
                placeholder="Código Postal"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Nota -->
          <b-form-group label="Nota">
            <validation-provider
              #default="{ errors }"
              name="Nota"
              rules="max:400"
            >
              <b-form-textarea
                size="sm"
                v-model="user.direction.note"
                name="note"
                xlength="500"
                :state="errors.length > 0 ? false : null"
                placeholder="Danos más información de tu dirección"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-card-title class="card-title m-0 pt-1">
            <h3 id="s11" class="title">Datos de facturación</h3>
            <hr />
          </b-card-title>

          <!-- Condición IVA -->
          <b-form-group label="Condición IVA">
            <validation-provider
              #default="{ errors }"
              name="Condición IVA"
              rules="required"
            >
              <b-form-select
                size="sm"
                v-model="user.vat_condition"
                name="vat_condition"
                @input="onVatCondition"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                :options="vat_conditions"
              >
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Tipo de Comprobante -->
          <b-form-group label="Tipo de Comprobante">
            <validation-provider
              #default="{ errors }"
              name="Tipo de Comprobante"
              rules="required"
            >
              <b-form-select
                size="sm"
                v-model="user.voucher_type"
                name="voucher_type"
                :state="errors.length > 0 ? false : null"
                :disabled="!!user.keopsUser"
                :options="voucher_types"
              >
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-card-title class="card-title m-0 pt-1">
            <h3 id="s12" class="title">Datos de envío</h3>
            <hr />
          </b-card-title>

          <b-row>
            <b-col>
              <!-- Servicio de Entrega -->
              <b-form-group label="Servicio de Entrega">
                <validation-provider
                  #default="{ errors }"
                  name="Servicio de colecta"
                  rules="required"
                >
                  <b-form-select
                    v-model="user.collection"
                    name="collection"
                    :state="errors.length > 0 ? false : null"
                    disabled
                    :options="collections"
                  >
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-row>
              <b-col
                v-if="user.collection == 'client'"
                md="12"
                style="margin-bottom: 5px"
              >
                <span style="font-size: 14px"
                  >Vamos a buscar tus paquetes dentro de este rango
                  horario</span
                >
              </b-col>
              <b-col v-if="user.collection == 'client'">
                <b-form-group description="Hora de inicio">
                  <b-form-timepicker
                    v-model="user.collection_start_time"
                    name="collection_start_time"
                    :hour12="false"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
              <b-col v-if="user.collection == 'client'">
                <b-form-group description="Hora de fin">
                  <b-form-timepicker
                    v-model="user.collection_end_time"
                    name="collection_end_time"
                    :hour12="false"
                  ></b-form-timepicker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-row>

          <!-- Punto de Entrega -->
          <b-form-group
            label="Punto de Entrega"
            description="Selecciona el Punto de preferencia para depositar tus paquetes"
          >
            <validation-provider
              #default="{ errors }"
              name="Punto de Entrega"
              rules="required"
            >
              <v-select
                size="sm"
                v-model="user.point"
                name="point"
                :state="errors.length > 0 ? false : null"
                :options="pointsFilter"
                label="fullText"
              >
                <template slot="option" slot-scope="option">
                  <div class="p-1 m-1 border-primary">
                    <div>
                      <b>Provincia:</b>
                      {{ option.province }}
                    </div>
                    <div>
                      <b>Localidad:</b>
                      {{ option.location }}
                    </div>
                    <div>
                      <b>Calle:</b>
                      {{ option.street }}
                    </div>
                    <div>
                      <b>Altura:</b>
                      {{ option.height }}
                    </div>
                  </div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <div
              v-if="user.point && user.point.longitude && user.point.longitude"
            >
              <p class="mt-1">
                <a :href="url" target="_blank" class="pl-2">
                  <feather-icon size="20" icon="MapIcon" class="mr-50" />
                  Ver punto en Google
                </a>
              </p>

              <p>Horario</p>
              <table
                class="table table-sm mt-1"
                style="width: 100%"
                v-if="user.point.schedules.length > 0"
              >
                <tr>
                  <th></th>
                  <th>Lunes</th>
                  <th>Martes</th>
                  <th>Miercoles</th>
                  <th>Jueves</th>
                  <th>Viernes</th>
                  <th>Sabado</th>
                  <th>Domingo</th>
                </tr>
                <tr>
                  <td>Apertura</td>
                  <td>
                    {{ user.point.schedules[0].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[1].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[2].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[3].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[4].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[5].start | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[6].start | hour_format }}
                  </td>
                </tr>
                <tr>
                  <td>Cierre</td>
                  <td>
                    {{ user.point.schedules[0].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[1].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[2].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[3].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[4].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[5].end | hour_format }}
                  </td>
                  <td>
                    {{ user.point.schedules[6].end | hour_format }}
                  </td>
                </tr>
              </table>
              <p class="mt-1">Nota: {{ user.point.note }}</p>
            </div>
          </b-form-group>

          <!-- Zona para seguros-->
          <b-form-group
            label="Seguros para tu envíos"
            description="Selecciona las áreas en las que deseas asegurar tus envios"
          >
            <b-input-group>
              <!-- <b-input-group-prepend></b-input-group-prepend> -->
              <validation-provider name="Zona para seguros">
                <b-form-checkbox-group
                  v-model="user.is_secured"
                  :options="options"
                ></b-form-checkbox-group>
              </validation-provider>
            </b-input-group>
          </b-form-group>

          <!-- Servicio de entrega -->
          <b-form-group
            label="Servicio de entrega"
            description="Selecciona que servicio de entrega a tus clientes querés ofrecer"
          >
            <b-input-group>
              <!-- <b-input-group-prepend></b-input-group-prepend> -->
              <validation-provider
                #default="{ errors }"
                name="Servicio de entrega"
                rules="required"
              >
                <b-form-select
                  size="sm"
                  v-model="user.retirement"
                  name="retirement"
                  :state="errors.length > 0 ? false : null"
                  :options="retirements"
                  disabled
                >
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-input-group>
          </b-form-group>

          <b-button class="mt-4 pull-right" variant="primary" @click="onSubmit"
            >Guardar</b-button
          >
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import UserService from "@/services/UserService";
import LocationService from "@/services/LocationService";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";

// Import component
export default {
  name: "BusinessData",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      selected: null,
      isAdmin: UserService.isAdmin(),
      points: [],
      locations: [],
      provinces: [],
      user: null,
      documents: [
        { text: "DNI", value: "dni" },
        { text: "CUIT/CUIL", value: "cuit" },
      ],
      voucher_types: [],
      collections: [
        {
          text: "Cliente",
          value: "client",
        },
        {
          text: "Drop-Off",
          value: "deposit",
        },
      ],
      retirements: [
        {
          text: "Domicilio",
          value: "client",
        },
        {
          text: "Punto de retiro",
          value: "point",
        },
        {
          text: "Domicilio y Punto de retiro",
          value: "client & point",
        },
      ],
      options: [
        {
          text: "Capital Federal",
          value: "CABA",
        },
        {
          text: "GBA",
          value: "GBA",
        },
        {
          text: "La Plata",
          value: "PLATA",
        },
        {
          text: "Interior del país",
          value: "INTERIOR",
        },
      ],
    };
  },
  methods: {
    onTypeDocument() {
      if (this.user && this.user.type_document === "dni") {
        this.vat_conditions = [
          { value: "Consumidor Final", text: "Consumidor Final" },
        ];
      } else {
        this.vat_conditions = [
          { value: "Responsable Inscripto", text: "Responsable Inscripto" },
          { value: "Monotributista", text: "Monotributista" },
          { value: "Exento 0%", text: "Exento 0%" },
          { value: "No gravado", text: "No gravado" },
          { value: "Otro", text: "Otro" },
        ];
      }
    },
    onVatCondition() {
      if (
        this.user &&
        (this.user.vat_condition === "Consumidor Final" ||
          this.user.vat_condition === "Exento 0%")
      ) {
        this.voucher_types = [{ value: "Factura B", text: "Factura B" }];
        return;
      }

      if (
        this.user &&
        (this.user.vat_condition === "Responsable Inscripto" ||
          this.user.vat_condition === "Monotributista")
      ) {
        this.voucher_types = [{ value: "Factura A", text: "Factura A" }];
        return;
      }

      this.voucher_types = [
        { value: "Factura A", text: "Factura A" },
        { value: "Factura B", text: "Factura B" },
      ];
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.businessDataForm.validate().then((valid) => {
        if (valid) {
          if (this.user.is_secured) {
            this.user.is_secured = this.user.shipping_areas
              .filter((d) => this.user.is_secured.includes(d.zone))
              .map((d) => d.id);
          }
          UserService.saveMe(this.user)
            .then(({ data }) => {
              this.$store.commit("user", data.data);
              UserService.setUser(data.data);
              if (data.data.retirement === "client & point") {
                if (this.user.keopsUser) {
                  this.$swal({
                    type: "info",
                    title: "Operación exitosa",
                    customClass: AlertService.customClass,
                    confirmButtonColor: AlertService.confirmButtonColor,
                  });
                } else {
                  this.$swal({
                    type: "info",
                    title: "Operación exitosa",
                    text: "Estamos analizando tus datos. Este proceso puede demorar de 1 a 5 minutos. Te enviaremos un email una vez que hayamos completado la validación",
                    customClass: AlertService.customClass,
                    confirmButtonColor: AlertService.confirmButtonColor,
                  });
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar Datos`,
                    icon: "InfoIcon",
                    variant: "info",
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
              const response = error.response;
              if (response && response.status === 422) {
                const keys = Object.keys(response.data.errors);
                keys.forEach((key) => {
                  response.data.errors[key].forEach((error) => {
                    this.veeErrors.add({
                      field: key,
                      msg: error,
                    });
                  });
                });
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Actualizar`,
                    text: `Error al tratar de almacenar este elemento`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },

    /**
     *
     */
    async fetchLocations() {
      const response = await LocationService.locations({
        province_id: this.user.direction.province_id,
      });
      const { data } = response;
      this.locations = data.data;
    },

    /**
     *
     */
    async fetchProvinces() {
      const response = await LocationService.provinces();
      const { data } = response;
      this.provinces = data.data;
    },

    /**
     *
     */
    async fetchPoints() {
      try {
        const data = await OperationService.points();
        this.points = data.data.data.map((item) => {
          item.fullText = [
            item.province,
            item.location,
            item.street,
            item.height,
          ]
            .filter((i) => i)
            .join(", ");
          return item;
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     *
     */
    async loadData() {
      this.user = Object.assign({}, this.$store.state.user);
      this.user.is_secured = UserService.user()
        .shipping_areas.filter((d) => {
          if (d.is_secured) return d.zone;
        })
        .map((d) => d.zone);
      this.user.direction = Object.assign({}, this.$store.state.user.direction);
      this.onTypeDocument();
      this.onVatCondition();
      if (this.user.point) {
        this.user.point.fullText = [
          this.user.point.location.province.province,
          this.user.point.location.location,
          this.user.point.street,
          this.user.point.height,
        ]
          .filter((i) => i)
          .join(", ");
      }
    },

    /**
     *
     */
    onProvince() {
      this.fetchLocations();
    },
  },
  watch: {
    user: {
      handler: function (newValue) {
        this.$emit("chageUser", newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.isLoading = true;
    Promise.all([
      this.loadData(),
      this.fetchProvinces(),
      this.fetchLocations(),
      this.fetchPoints(),
    ])
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        console.errro(error);
      });
  },
  computed: {
    url: function () {
      return (
        "https://www.google.com/maps?q=" +
        this.user.point.latitude +
        "," +
        this.user.point.longitude
      );
    },

    vat_conditions: function () {
      if (this.user && this.user.type_document == "dni") {
        return [{ value: "Consumidor Final", text: "Consumidor Final" }];
      }

      return [
        { value: "Responsable Inscripto", text: "Responsable Inscripto" },
        { value: "Monotributista", text: "Monotributista" },
        { value: "Consumidor Final", text: "Consumidor Final" },
        { value: "Exento 0%", text: "Exento 0%" },
        { value: "No gravado", text: "No gravado" },
        { value: "Otro", text: "Otro" },
      ];
    },

    pointsFilter: function () {
      if (this.user.collection == "deposit") {
        return this.points.filter((d) => d.dropoff);
      }
      return this.points;
    },
  },
};
</script>
