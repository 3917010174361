<template>
  <b-card class="p-2" style="border-radius: 10px">
    <b-card-title class="card-title">
      <h3 class="title">Cambiar contraseña</h3>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <b-form>
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
        <b-form-group label="Email">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required|email"
          >
          <b-input-group>
            <b-form-input
              class="form-control"
              v-model="user.email"
              :state="errors.length > 0 ? false:null"
              type="text"
              placeholder="Email"
              autocomplete="email"
              disabled
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        </b-form-group>
        <b-form-group label="Contraseña">
          <validation-provider
              #default="{ errors }"
              name="password"
              rules="required|min:5"
            >
          <b-input-group>
            <b-form-input
              class="form-control-merge"
              v-model="user.password"
              :state="errors.length > 0 ? false:null"
              type="password"
              autocomplete="password"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Confirmar contraseña">
          <validation-provider
            #default="{ errors }"
            name="c_password"
            rules="required|confirmed:password"
          >
          <b-input-group class="mb-2">
            <b-form-input
              class="form-control"
              v-model="user.c_password"
              :state="errors.length > 0 ? false:null"
              type="password"
              autocomplete="c_password"
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        </b-form-group>
        <b-button
          class="pull-right btn-update-password"
          @click="updatePassword"
        >Cambiar Contraseña</b-button>
      </b-form>
    </b-card-body>
  </b-card>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BCardTitle, BCardBody, BForm, BFormGroup, BButton, BInputGroup, BFormInput } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import CustomerService from "../../../../services/CustomerService";
import { required, email } from '@validations'

// Import component
export default {
  name: "BasicData",
  components:{
    BCard,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BButton,
    ValidationProvider,
    BInputGroup,
    BFormInput,
  },
  data() {
    return {
      required,
      email,
      isLoading: false,
      fullPage: false,
      selected: null,
      user: {
        email: "tech@gmail.com",
        password: null,
        c_password: null
      }
    };
  },
  methods: {
    updatePassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          // do stuff if not valid.
          this.isLoading = true;
          CustomerService.update_password(this.user)
            .then(() => {
              this.isLoading = false;
              this.$swal({
                title: "Los datos se han guardado correctamente",
                type: "success"
              });
            })
            .catch(error => {
              const status = error.response.status;
              console.error(status);
              this.isLoading = false;
              if (status == 422) {
                const errors = error.response.data.errors;
                const keys = Object.keys(errors);
                keys.forEach(property => {
                  errors[property].forEach(value => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: `Error`,
                        text: `${value}`,
                        icon: "AlertOctagonIcon",
                        variant: "danger",
                      },
                    });
                  });
                  this.user[property] = null;
                  this.$validator.flag(property, {
                    valid: false,
                    dirty: false
                  });
                });
              } else if (status == 500) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Error`,
                    text: `Hubo un error al procesar la solicitud`,
                    icon: "AlertOctagonIcon",
                    variant: "danger",
                  },
                });
              }
            });
        }
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  },
  mounted() {
    let user = this.$store.state.user;
    this.user.email = user.email;
  }
};
</script>

<style lang="scss" scoped>
  .btn-update-password{
    background: rgb(246, 141, 30) !important;
    border: none;
  }
</style>
