<template>
  <b-card class="p-2" style="border-radius: 10px">
    <b-card-body class="card-body py-2 px-0">
      <b-form>
        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="fullPage"
        ></loading>
        <b-form-group label=" ">
          <b-input-group>
            <b-form-checkbox
              v-model="label_products"
              @change="updateLabelProducts"
              name="label_products"
            >
              Activar Rotulo de productos
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
} from "bootstrap-vue";
import { required } from "@validations";
import UserService from "@/services/UserService";

// Import component
export default {
  name: "LabelProductData",
  components: {
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
  },
  data() {
    return {
      required,
      label_products: false,
      isLoading: false,
      fullPage: false,
      user: null,
    };
  },
  methods: {
    updateLabelProducts(item) {
      this.isLoading = true;
      UserService.updateLabelProduct(item).then(() => {
        this.isLoading = false;
        this.user.label_products = item;
      });
    },
  },
  mounted() {
    this.user = this.$store.state.user;
    this.label_products = this.user.label_products;
  },
};
</script>

<style lang="scss" scoped>
.btn-update-password {
  background: rgb(246, 141, 30) !important;
  border: none;
}
</style>
