<template>
  <b-card class="p-2">
    <b-card-title class="card-title m-0">
      <h3 class="title">Credenciales de Integración</h3>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
      <p class="mt-2">
        Genera un token en caso de necesitar integrar Enviamelo a alguna plataforma externa.
      </p>
      <b-form-group>
        <b-row class="justify-content-center">
          <b-col>
            <b-button
              @click="generateToken()"
              v-if="last_token == null"
              class="mr-50 button-primary"
            >Generar token</b-button>
            <b-button
              @click="showLastToken"
              variant="primary"
              v-if="last_token != null"
            >Ver último token generado</b-button>
          </b-col>
        </b-row>
      </b-form-group>
      <p>Historial de credenciales</p>
      <full-table-component ref="fulltable" :fields="fields" url="/customers/token">
        <template slot="buttons-slot" slot-scope="props">
          <div class="custom-actions">
            <button class="btn btn-sm" @click="onDelete(props.rowData)">
              <feather-icon
                size="20"
                icon="TrashIcon"
              />
            </button>
          </div>
        </template>
      </full-table-component>
    </b-card-body>
  </b-card>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerService from "@/services/CustomerService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import ChannelService from "@/services/ChannelService";

import {  FullTableComponent } from "../../../../layouts/components";

import GenerateToken from "./GenerateToken";

export default {
  name: "CredentialData",
  components: {
    FullTableComponent,
  },
  data() {
    return {
      isLoading: false,
      fullPage: false,
      selected: null,
      last_token: null,
      fields: [
        {
          name: "id",
          titleClass: "center aligned",
          dataClass: "right aligned",
          visible: false
        },
        {
          name: "created_at",
          title: "Fecha de creacion",
          formatter: value => {
            return UtilService.date(value);
          }
        },
        {
          name: "name",
          title: "Scope"
        },
        {
          name: "expires_at",
          title: "Fecha de expiracion",
          formatter: value => {
            return UtilService.date(value);
          }
        },

        {
          name: "buttons-slot",
          title: "Opciones",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      channels: [],
      user: {}
    };
  },
  methods: {
    /**
     *
     */
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return [null];
    },

    /**
     *
     */
    showLastToken() {
      this.$swal({
        title: "El token se ha generado correctamente",
        input: "textarea",
        inputValue: this.last_token,
        text:
          "copialo y guardalo en un lugar seguro, no se guardaran los cambios en la pagina",
        type: "success"
      });
    },

    /**
     *
     */
    generateToken() {
      this.$modal.show(
        GenerateToken,
        {
          onClose: {
            handler: () => {}
          },
          onSave: {
            handler: item => {
              this.last_token = item.accessToken;
              this.$refs.fulltable.refresh();
            }
          }
        },
        {
          adaptive: true,
          width: "50%",
          height: "auto"
        }
      );
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "¿Estas seguro de eliminar este token?, si ya lo tienes integrado otra aplicacion perdera su efecto"
      );
      if (result.value) {
        this.isLoading = true;
        CustomerService.destroy_token(item.id)
          .then(() => {
            this.isLoading = false;
            AlertService.info();
            this.$refs.fulltable.refresh();
          })
          .catch(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    fetchChannels() {
      ChannelService.channels().then(({ data }) => {
        this.channels = data.data;
      });
    }
  },
  async mounted() {
    await this.fetchChannels();
  }
};
</script>
<style>
.input-error {
  border: 1px solid red;
}
.error {
  color: red;
}
</style>
