
<template>
  <div class="m-2">
    <validation-observer ref="generateTokenForm">
    <b-form @submit="onSubmit">
      <div class="card-body card-modal">
        <div class="title my-1">
         <span class="d-flex d-block h3"> Generar Token </span>
          <span class="h4"> Genera un token en caso de necesitar integrar <span style="color: #f68d1e">Enviamelo</span> a alguna plataforma externa. </span>
        </div>
        <div class="row justify-content-center" v-if="!token">
          <div class="col-12">
            <!-- Canal -->
            <b-form-group
              label="Canal"
            >
              <validation-provider
                #default="{ errors }"
                name="Canal"
              >
                <b-form-select
                  size="sm"
                  name="channel"
                  v-model="channel"
                  :options="channels"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div v-else>
          <textarea id="token" class="form-control" v-model="token" cols="30" rows="10"></textarea>
        </div>
      </div>
      <footer class>
        <div class="row">
          <div class="col-12" v-if="!token">
            <b-button size="sm" type="submit" class="mr-1 float-right" variant="primary">Generar</b-button>
            <b-button
              size="sm"
              type="button"
              class="mr-1 float-right"
              variant="outline-primary"
              @click="close"
            >Cancelar</b-button>
          </div>
          <div class="col-12" v-else>
            <b-button
              size="sm"
              type="button"
              class="mr-1 float-right"
              @click="onCopy"
              variant="primary"
            >Copiar</b-button>
          </div>
        </div>
      </footer>
    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ChannelService from "@/services/ChannelService";
import CustomerService from "@/services/CustomerService";

export default {
  name: "GenerateToken",
  components:{
    ValidationProvider,
    ValidationObserver
  },
  props: {
    onClose: Object,
    onSave: Object
  },
  data() {
    return {
      token: null,
      isLoading: false,
      channels: [],
      channel: null
    };
  },
  methods: {
    /**
     *
     */

    onCopy() {
      const copyText = document.getElementById("token");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Copiado`,
          text: `Token copiado`,
          icon: "InfoIcon",
          variant: "info",
        },
      });
    },

    /**
     *
     */
    close() {
      this.$emit("close");
    },

    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.$refs.generateTokenForm.validate().then(valid => {
        if (valid) {
          this.isLoading = true;
          CustomerService.generateToken(this.channel)
            .then(resp => {
              this.isLoading = false;
              this.onSave.handler(resp.data);
              this.token = resp.data.accessToken;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `${this.channel}`,
                  text: `Token generado`,
                  icon: "InfoIcon",
                  variant: "info",
                },
              });
            })
            .catch(error => {
              console.error(error);
              this.isLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Hubo un error al procesar la solicitud`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    /**
     *
     */
    fetchChannels() {
      this.isLoading = true;
      ChannelService.channels().then(({ data }) => {
        this.isLoading = false;
        this.channels = data.data.map(d => d.name);
        this.channel = this.channels[0];
      });
    }
  },
  async mounted() {
    await this.fetchChannels();
  }
};
</script>